.testiminal_image {
  /* width: 100%;  */
  width: 90%;
  height: auto;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Maintain aspect ratio */
}

.testiminal_user_image_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


.testiminal_user_image {
  /* width: 250px; */
  filter: grayscale(100%);
  padding: 10px;
  display: none;
  border-radius: 50%;
}



.user_info-message {
  margin-bottom: 25px;
  font-size: 20px;
}

/* for small screen */
@media (max-width: 600px) {
  .user_info-message {
    font-size: 10px;
    margin-bottom: 15px;
  }
}

/* for tablet screen */
@media (min-width: 768px) {
  .user_info-message {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .testiminal_user_image {
    padding: 10px;
    width: 180px;
    display: block;
  }

  .testiminal_image {
    /* width: 0%; */
  }

  .testiminal_user_image_div {
    transform: translate(-50%, -50%);
  }

  .testiminal_user_image_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 930px) {
  .testiminal_user_image {
    width: 110px;
  }

  .user_info-message {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  .user_info-message {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .testiminal_user_image {
    padding: 10px;
    width: 140px;
    height: 100%;
  }
}

@media (min-width: 1440px) {
  .user_info-message {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .testiminal_user_image {
    padding: 10px;
    width: 150px;
  }
}

@media (max-width: 950px) {
  .testimonail_for_big_screen {
    display: none;
  }
}

@media (min-width: 950px) {
  .testimonail_for_small_screen {
    display: none;
  }
}

@media (max-width: 600px) {
  .user_info-message {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .user_info-message {
    font-size: 13px;
    margin-bottom: 20px;
  }
}

@media (min-width: 2000px) {
  .user_info-message {
    font-size: 30px;
    margin-bottom: 20px;
  }
}