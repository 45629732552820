.back-event-image {
  background-image: url("../../images/Photo_Gallery/event_header_image.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: relative; */
  background-position: center;
  /* z-index: 0; */

}
.back-event-image::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  /* background-color: rgba(192, 57, 57); */
}

.Event_header_icon {
  width: 60%;
}
.Event_logo_div {
  z-index: 1;
  padding-left: 200px;
  padding-top: 100px;
  /* margin-top: 50px; */
}
.typing-animation {
  overflow: hidden; 
  animation: typing 3s steps(15, end); 
  white-space: nowrap; 
  border-right: 3px solid #ffffff; 
  font-size: 220px;
  color: white;
      filter  :drop-shadow(2px 4px 6px black);
}


@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}



@media (max-width: 767px) {
  .Event_logo_div{
    /* width: 20%;/ */
    /* background: #000; */
    /* width: 400px; */
  }
.typing-animation {
  font-size: 100px;
}
}
@media (max-width: 768px) {
  .Event_header_icon {
    width: 70%;
  }
  .Event_logo_div {
    padding-left: 50px;
    margin-top: 50px;
  }
  
}
