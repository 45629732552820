.header-logo {
  width: 250px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.header-logo:hover {
  transform: scale(1.2);
}

.back-ground-tea-image {
  /* background-image: url("../images/TEA\ SMOKE\ 2.gif"); */
  background-image: url("../images/home/Saburi-Video.gif");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .back-ground-tea-image {
    /* background-size: 100% 100%; */
    background-size: cover;
    background-image: url("../images/home_banner_for_big_screen.gif");
  }
}

.vertical-navbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200px;
  background-color: #40aa0c9e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  font-family: "Arial", sans-serif;
  animation: fadeIn 0.5s ease-in-out;
  color: white;
}

.vertical-navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vertical-navbar li {
  padding: 10px 20px;
  border-bottom: 1px solid #46464636;
}

/* navbar for big screen */
/* .main_navbar_for_big_screen{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  color: white;
  padding: 5px;
  font-family: sans-serif;
  position: fixed;
  width: 100%;
  z-index: 1;
} */
.main_navbar_for_big_screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  color: white;
  padding: 5px;
  font-family: sans-serif;
  width: 100%;
  /* z-index: 0; */
}

/* .fixed {
  position: fixed;
  top: 0;
} */

.absolute {
  position: absolute;
  top: 0;
}

.big_screen_navbar_ul {
  display: flex;
  justify-content: space-around;

}

.navbar_for_big_screen_left_side_div {
  max-width: 100%;
  padding: 0px 30px;
}

.navbar_for_big_screen_right_side_div {
  width: 70%;
  padding-top: 20px;

}

.navbar_for_big_screen_right_side_div ul li {
  text-transform: uppercase;
}

.Header_logo_for_big_screen {
  width: 120px;
}

@media (max-width: 500px) {
  .header-logo {
    width: 150px;
    height: auto;
  }
}

@media (min-width: 500px) and (max-width: 769px) {
  .header-logo {
    width: 200px;
    height: auto;
  }
}

@media (min-width: 2000px) {
  .Header_logo_for_big_screen {
    width: 180px;
    height: auto;
  }

  .main_navbar_for_big_screen {
    font-size: 25px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}