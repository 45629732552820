.main-have-a-question-contact-us {
  background-image: url("../../images/contact_us_img/contact-us-main-back-Image.png");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* padding: 50px 0px; */
}
.have_a_question_main_form_before_start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.have_a_question_main_form div {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}
.have_a_question_main_form_input_div input,
textarea {
  width: 300px;
  border: 1px solid black;
  border-radius: 12px;
  padding: 14px;
}
/* button */
.have_a_question_save_button_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.have_a_question_save_button {
  cursor: pointer;
  padding: 10px 45px;
  border-radius: 13px;
  background: #367125;
  color: white;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -9px;
}

@media (min-width: 2000px) {
  .have_a_question_main_form div {
    margin: 30px 0px;
  }
  .have_a_question_main_form_input_div input,
  textarea {
    width: 500px;
    padding: 25px;
    font-size: 25px;
  }
  .have_a_question_text {
    font-size: 70px;
  }
  .have_a_question_save_button {
    padding: 20px 145px;
    font-size: 35px;
  }
  .main-have-a-question-contact-us{
    background-size: cover;
  }
}
