.main-slider-div {
  padding: 20px 14px;
  position: relative;
  margin: 0px 10px;

}

.main-slider-section-start {

  background: var(--gay-main-bg-color);
  /* margin-bottom: 10px; */
  padding-bottom: 10px;


}

.our-product-text {
  font-size: 20px;
  font-family: Georgia;
}

.our-product-slider-start {
  /* margin-top: 20px; */
}

.mySwiper {
  /* width: 100%; */
  /* margin: 0px 10px; */
}

.mySwiper .swiper-slide {
  /* text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px; */
}

/* .mySwiper .swiper-slide img,
iframe  */
.slider_images {
  display: block;
  width: 100%;
  margin-top: 30px;
  object-fit: cover;
}

.our-product-img {
  width: 65%;
  margin: auto;
  margin-top: 30px;
}

.append-buttons {
  /* text-align: center; */
  /* margin-top: 20px; */
}

.append-buttons button {
  /* display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px; */
}

.swiper-button-next {
  /* color: black !important; */
}

.swiper-button-prev {
  /* color: black !important; */
}

.swiper-button-next:after {
  /* font-size: 20px !important; */
}

.swiper-button-prev:after {
  /* font-size: 20px !important; */
}

.prev-next-buttons {
  /* display: flex; */
  /* justify-content: space-between; */
}

.prev-button {
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
}

.next-button {
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
}

.text-buttm-icon {
  /* width: 500px; */
}

.text-style-icon-div {
  /* display: flex; */
  /* justify-content: center; */
}

@media (max-width: 500px) {

  .mySwiper .swiper-slide img,
  iframe {
    width: 100%;
    height: 300%;
    /* border-radius: 8px; */
  }

  .our-product-img {
    /* width: 80px; */
    padding: 40px;
  }
}

.second-about-us-image {
  background-image: url("../images/new_about_2.png");
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
}

/* .our-collection-icons {
  filter: invert(1);
} */

/* for laptop */
@media (min-width:768px) {
  .second-about-us-image {
    background-image: url("../images/home_tea_image_for_big_screen.webp");
  }
}