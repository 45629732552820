body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* google font */
@import url("https://fonts.googleapis.com/css2?family=Allura&family=Cedarville+Cursive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&family=Cedarville+Cursive&family=Kotta+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* root var */
:root {
  --gay-main-bg-color: #e9eaef;
  --our-product-font-family: "Allura", cursive;
  --our-product-kotta-one-font-family: "Kotta One", serif;
  --our-address-font-family: "Montserrat", sans-serif;
  --Lato-font-family: "Lato", sans-serif;
}

.heading-text {
  text-align: center;
  font-size: 26px;
  font-family: Georgia;
  text-transform: uppercase;
}

.text-icon-bottom {
  width: 155px;
  margin: -30px;
}

@media (min-width:1023px) {
  .heading-text {
    font-size: 38px;
  }

  .text-icon-bottom {
    width: 155px;
    /* margin: 5px; */
  }
}



.rotate-180 {
  transform: rotate(180deg) scale(-1);
}