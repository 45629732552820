.live-event-main {
  background: #e0fbd0;
}
.Live-event-text{
  font-size : 50px;
  font-weight: bold;
}
@media (max-width: 500px) {
  .Live-event-text{
    font-size : 30px;
    word-spacing: 10px; 
  }
}

.live-event-section .mySwiper .swiper-slide img {
  display: block;
  /* width: 164%; */
  /* height: 53%; */
  object-fit: cover;
  border-radius: 8px;
}

.next-button-live-event {
  position: absolute;
  top: 55%;
  right: 0;
}
.prev-button-live-event {
  position: absolute;
  top: 55%;
  left: 0;
}
