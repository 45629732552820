.back-our-product-image {
  background-image: url("../../../images/our-product-img/Birds\ GIF.gif");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* for table screen */
@media (min-width: 760px) {
  .back-our-product-image {
    background-image: url("../../../images/our-product-img/out-product-header-for-big-screen.png");
  }
}
