

.event_images {
  width: 100%;
}
.event_image_parent_div {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 90px;
}

/* image */
.Event_image_self{
  transition: all 1s ease-in;
  cursor: pointer;
  border-radius: 20px;

}
.Event_image_self:hover{
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(0.9); 
}

/* top */
.top_photo_gallery_section{
    display: flex;
    width: 100%;
    gap: 20px;
}
.top_photo_gallery_section_left{
    width: 100%;
}
.top_photo_gallery_section_right{
    width: 74%;
}
/* center */

/* image end */

@media (max-width: 767px) {
  .photo_gallery_card_for_big_screen {
    display: none;
  }
}
@media (min-width: 768px) {
  .photo_gallery_card_for_small_screen {
    display: none;
  }
  .photo_gallery_card_for_big_screen{
    padding: 50px;
  }
}
