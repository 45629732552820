.back-contact-us-image {
    background-image: url("../../images/contact_us_img/contact-us-header.png");
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .contact-us-icon-header-logo{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
  }
  .contact-us-icon{
    width: 300px;
  }
  .navbar-icon{
    color: white;
  }

@media (min-width: 2000px) {
  .contact-us-icon{
    width: 600px;
  }
}