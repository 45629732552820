.back-about-image {
    background-image: url("../../images/About/about-header.png");
    width: 100%;
    height: 100vh;
    /* background-size: 100% 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

@media (min-width: 760px) {
.back-about-image {
  background-image: url("../../images/About/about-saburi-header-big-screen.png");
  background-size: 100% 100%;
}
}
@media (min-width: 1000px) {
.back-about-image {
  background-size: cover;

}
}