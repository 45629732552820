.main-our-product-section {
  background-image: url("../images/Leaf.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
  /* background-attachment: fixed; */
}

.tea-images {
  width: 500px;
  height: auto;
}
.tea-images-left {
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: black;
}
.tea-images-right {
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: black;
}
.main-card-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 50px;
}

.our-collection-cards {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
  margin: 50px 0px;
}

.explore-more-button {
  background: #d8c9aa;
  border: none;
  font-weight: 200;
  font-family: cursive;
}

.our-collection-card-text {
  font-family: cursive;
  font-size: 20px;
}

.right-side-our-product-div {
  min-height: 560px;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
  border-color: black;
  z-index: 2;
  min-width: 300px;
  position: absolute;
  top: 52%;
  right: 0;
  transform: translateY(-50%);
}
.left-side-our-product-div {
  min-height: 1650px;
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  border-color: black;
  z-index: 2;
  min-width: 450px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 550px) {
  .tea-images {
    width: 260px;
    height: auto;
  }

  .right-side-our-product-div {
    min-height: 350px;
    min-width: 100px;
    top: 50%;
  }
  .left-side-our-product-div {
    min-height: 950px;
    min-width: 220px;
    top: 53%;
  }
  .our-collection-cards {
    gap: 20px;
  }
}

@media (min-width: 552px) /* and (max-width: 769px) */ {
  .tea-images {
    width: 390px;
    height: auto;
  }
  .left-side-our-product-div {
    min-height: 1300px;
    top: 51%;
    min-width: 350px;
  }
  .right-side-our-product-div {
    min-height: 470px;
    min-width: 150px;
    top: 50%;
  }
  .our-collection-cards {
    gap: 50px;
  }
  .our-collection-card-text {
    font-size: 40px;
  }
}

@media (min-width: 1023px) /* and (max-width: 769px) */ {
  .tea-images {
    width: 490px;
  }
  .left-side-our-product-div {
    min-height: 1700px;
    min-width: 400px;
  }
  .right-side-our-product-div {
    min-height: 690px;
    min-width: 280px;
  }
  .our-collection-cards {
    gap: 50px;
  }
  .our-collection-card-text {
    font-size: 50px;
  }
}

@media (min-width: 2000px) {
  .tea-images {
    width: 650px;
  }
  .left-side-our-product-div {
    min-height: 2100px;
    min-width: 500px;
  }
  .right-side-our-product-div {
    min-height: 890px;
    min-width: 450px;
  }
  .our-collection-cards {
    gap: 50px;
  }
  .our-collection-card-text {
    font-size: 60px;
  }

  .explore-more-button {
    font-weight: 300;
    font-size: 30px;
  }
}
